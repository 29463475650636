import React from 'react'

export const subscribeScreenOn = (listener: () => void) => {
  document.addEventListener('visibilitychange', listener)
  return () => {
    document.removeEventListener('visibilitychange', listener)
  }
}

const getScreenOn = () => {
  return document.visibilityState === 'visible'
}

export const useScreenOn = () => {
  return React.useSyncExternalStore(subscribeScreenOn, getScreenOn, () => true)
}
