import React from 'react'

export function useEventCallback<T extends unknown[], U>(
  fn: (...args: T) => U,
) {
  const ref = React.useRef(fn)
  React.useLayoutEffect(() => {
    ref.current = fn
  })

  // @ts-ignore
  return React.useCallback((...args: T) => (0, ref.current)(...args), [])
}
