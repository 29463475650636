import { useEventCallback } from '@paladise/utils/hooks/useEventCallback'
import { useEffect, useRef } from 'react'
import type { SessionTrackerBody } from '../types'

export const useVisibilityTracker = (
  sessionTrackerBody: SessionTrackerBody,
  sessionId: string,
  services: {
    sessionTrackerInActive: (
      body: SessionTrackerBody & { id: string },
    ) => Promise<unknown>
    sessionTrackerResumed: (
      body: SessionTrackerBody & { id: string },
    ) => Promise<unknown>
  },
) => {
  const initialTrackerRef = useRef<boolean>(false)

  const handleResumedTracker = useEventCallback(() => {
    return services.sessionTrackerResumed({
      ...sessionTrackerBody,
      id: sessionId,
    })
  })

  const handleInactiveTracker = useEventCallback(() => {
    return services.sessionTrackerInActive({
      ...sessionTrackerBody,
      id: sessionId,
    })
  })

  useEffect(() => {
    const handleVisibilityChange = () => {
      document.visibilityState === 'visible'
        ? handleResumedTracker()
        : handleInactiveTracker()
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    if (!initialTrackerRef.current) {
      initialTrackerRef.current = true
      handleResumedTracker()
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [handleInactiveTracker, handleResumedTracker])
}
