import type { SessionTrackerBody } from '@paladise/tracker/types'
import type { Fetch } from 'lib/fetch/type'
import type { IResp } from 'type/common'

export const sessionTrackerBegin = async (
  fetcher: Fetch,
  body: SessionTrackerBody,
): Promise<IResp<{ id: string }>> => {
  const res = await fetcher(fetcher.API.SESSION + `/web/v1/session/begin`, {
    method: 'POST',
    body: JSON.stringify(body),
  })

  return res
}

export const sessionTrackerResumed = async (
  fetcher: Fetch,
  body: SessionTrackerBody & {
    id: string
  },
): Promise<IResp<{ id: string }>> => {
  const res = await fetcher(fetcher.API.SESSION + `/web/v1/session/resumed`, {
    method: 'POST',
    body,
  })

  return res
}

export const sessionTrackerInActive = async (
  fetcher: Fetch,
  body: SessionTrackerBody & {
    id: string
  },
): Promise<IResp<{ id: string }>> => {
  const res = await fetcher(fetcher.API.SESSION + `/web/v1/session/inactive`, {
    method: 'POST',
    body,
  })

  return res
}
