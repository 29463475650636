'use client'

import { useScreenOn } from 'hooks/useScreenOn'
import React from 'react'

type RegisterAudio = (
  id: string,
  onPlay: () => void,
  onEnded: () => void,
) => {
  setSource: (url: string) => void
  playAudio: () => Promise<void>
  pauseAudio: () => void
  unsubscribe: () => void
}

const AudioContext = React.createContext<{
  registerAudio: RegisterAudio
  initialize: () => void
}>(null!)

export const AudioProvider = ({ children }: { children: React.ReactNode }) => {
  const audioRef = React.useRef<HTMLAudioElement>(null)
  const sourceIdRef = React.useRef<string | null>(null)
  const onPlayListeners = React.useRef<Set<() => void>>(new Set())
  const onEndedListeners = React.useRef<Set<() => void>>(new Set())
  const initialRef = React.useRef(false)

  const screenOn = useScreenOn()

  React.useEffect(() => {
    if (!initialRef.current) {
      return
    }
    if (screenOn) {
      audioRef.current!.muted = false
      audioRef.current!.removeAttribute('muted')
    } else {
      audioRef.current!.muted = true
    }
  }, [screenOn])

  const registerAudio: RegisterAudio = React.useCallback(
    (id, onPlay, onEnded) => {
      sourceIdRef.current = id
      onPlayListeners.current.add(onPlay)
      onEndedListeners.current.add(onEnded)
      return {
        setSource: url => {
          if (!audioRef.current) return
          if (audioRef.current.src) {
            URL.revokeObjectURL(audioRef.current.src)
          }
          audioRef.current.src = url
        },
        playAudio: () => {
          if (!audioRef.current) return Promise.reject('No audio found')
          return audioRef.current.play()
        },
        pauseAudio: () => {
          if (!audioRef.current) return
          audioRef.current.pause()
          sourceIdRef.current = null
        },
        unsubscribe: () => {
          onPlayListeners.current.delete(onPlay)
          onEndedListeners.current.delete(onEnded)
          if (audioRef.current && sourceIdRef.current === id) {
            if (audioRef.current.src) {
              audioRef.current.pause()
              URL.revokeObjectURL(audioRef.current.src)
              audioRef.current.src = ''
            }
            sourceIdRef.current = null
          }
        },
      }
    },
    [],
  )

  const initialize = React.useCallback(() => {
    audioRef.current!.muted = false
    audioRef.current!.removeAttribute('muted')
    initialRef.current = true
  }, [])

  return (
    <AudioContext.Provider value={{ registerAudio, initialize }}>
      <audio
        ref={audioRef}
        className="absolute opacity-0"
        muted
        playsInline
        onPlay={() => {
          onPlayListeners.current.forEach(listener => listener())
        }}
        onEnded={() => {
          onEndedListeners.current.forEach(listener => listener())
          const audio = audioRef.current
          if (audio) {
            URL.revokeObjectURL(audio.src)
            audio.src = ''
          }
        }}
      ></audio>
      {children}
    </AudioContext.Provider>
  )
}

export const useAudio = () => {
  const context = React.useContext(AudioContext)
  if (!context) {
    throw new Error('useAvatar must be used within a AudioProvider')
  }
  return context
}
