import { env, v2Token } from 'store/server-context/static'
import { baseFetch } from './base'
import { Fetch } from './type'

export const CLIENT_API = {
  get ASGARD() {
    return env.API_ASGARD
  },
  get USER() {
    return env.API_USER
  },
  get MEDIA() {
    return env.API_MEDIA
  },
  get SESSION() {
    return env.API_SESSION
  },
  get HERMES() {
    return env.API_HERMES
  },
  get CHAT() {
    return env.API_CHAT
  },
  get REPORT() {
    return env.API_REPORT
  },
}

export const clientFetch: Fetch = baseFetch(CLIENT_API, () => v2Token)
