'use client'

import type { GuestInfo, UserInfo } from 'lib/auth'

interface IServerContext {
  session: UserInfo | GuestInfo
  v2Token: string
  location: {
    lat: string
    lng: string
  }
  env: {
    CONSUMER_BASE_URL: string
    ENV: string

    // API
    API_ASGARD: string
    API_USER: string
    API_MEDIA: string
    API_SESSION: string
    API_HERMES: string
    API_CHAT: string
    API_REPORT: string

    // HOST
    HOST_MQTT: string
    HOST_NOTIFY: string
    HOST_TRACKER: string
  }
}

export let session: IServerContext['session'] = undefined!
export let v2Token: IServerContext['v2Token'] = ''
export let location: IServerContext['location'] = { lat: '0', lng: '0' }

export let env: IServerContext['env'] = {
  CONSUMER_BASE_URL: '',
  ENV: '',

  // API
  API_ASGARD: '',
  API_USER: '',
  API_MEDIA: '',
  API_SESSION: '',
  API_HERMES: '',
  API_CHAT: '',
  API_REPORT: '',

  // HOST
  HOST_MQTT: '',
  HOST_NOTIFY: '',
  HOST_TRACKER: '',
}

export const StaticServerContext = (props: IServerContext) => {
  session = props.session
  v2Token = props.v2Token
  location = props.location
  env = props.env

  return null
}
